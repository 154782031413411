<template>
  <div class="container-login">
    <div class="box-login text-center">
      <!-- language -->
      <div class="language">
        <a-select
          id="login_select_language_option_box"
          v-model="currentLocale"
          class="select-language"
        >
          <a-select-option
            id="login_select_language_en_option"
            value="en"
            @click="switchLang('en')"
          >
            <img src="@/assets/images/en.svg" class="mr-2 iconlang" alt="" />ENG
          </a-select-option>
          <a-select-option
            id="login_select_language_th_option"
            value="th"
            @click="switchLang('th')"
          >
            <img src="@/assets/images/th.svg" class="mr-2 iconlang" alt="" />TH
          </a-select-option>
          <a-select-option
            id="login_select_language_ph_option"
            value="ph"
            @click="switchLang('ph')"
          >
            <img src="@/assets/images/ph.svg" class="mr-2 iconlang" alt="" />PH
          </a-select-option>
        </a-select>
      </div>
      <div class="mb-2">
        <img
          src="@/assets/images/newlogocolored.svg"
          alt=""
          class="logo-image"
        />
      </div>
      <!-- <div class="mb-3">
        <label>{{ lbl['select-page-welcome-to-crm-pLus'] }}</label>
      </div> -->
      <a-form-item class="text-left">
        <div v-if="isMessageError" class="mb-2">
          <a-alert type="error">
            <span slot="description" class="d-flex">
              <img src="@/assets/images/lock.svg" alt="" />
              <span class="lbl-error">{{
                lbl['new-login-error-message']
              }}</span>
            </span>
          </a-alert>
        </div>
        <div v-if="isMessageInputErrorUsername" class="mb-2">
          <a-alert type="error">
            <span slot="description" class="d-flex">
              <img src="@/assets/images/lock.svg" alt="" />
              <span class="lbl-error">
                {{ lbl['new-login-error-message-input-username'] }}
              </span>
            </span>
          </a-alert>
        </div>
        <div v-if="isMessageInputErrorPassword" class="mb-2">
          <a-alert type="error">
            <span slot="description" class="d-flex">
              <img src="@/assets/images/lock.svg" alt="" />
              <span class="lbl-error">
                {{ lbl['new-login-error-message-input-password'] }}
              </span>
            </span>
          </a-alert>
        </div>
        <div class="text-userpw">{{ lbl['login-username'] }}</div>
        <a-input id="login_username_input" v-model="username" class="mb-3">
        </a-input>
        <div class="text-userpw">{{ lbl['login-password'] }}</div>
        <a-input-password
          id="login_password_input"
          v-model="password"
          type="password"
        />
      </a-form-item>
      <a-button
        id="login_submit_button"
        class="btn_login"
        block
        @click="submitLogin()"
      >
        {{ lbl['new-login-button'] }}
      </a-button>
      <div class="text-right mb-5">
        <div
          id="login_forgot_password_link"
          class="forgotpw"
          @click="handleForgotPassword"
        >
          {{ lbl['login-forgot-password'] }}
        </div>
      </div>
      <span v-if="isOpenPackage" class="login-didnot"
        >{{ lbl['login-did-not-have-an-account'] }}
        <a
          id="login_register_link"
          class="btn-register-login"
          @click="handleRegister()"
          >{{ lbl['login-create-account'] }}</a
        ></span
      >
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import Vue from 'vue'
import Locale from '@/helper/locale.js'
import Account from '@/helper/AccountHelper.js'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import Cache from '@/helper/Cache.js'

export default {
  name: 'Login',
  components: {},
  mixins: [Mixin],
  data() {
    return {
      currentLocale: 'en',
      username: '',
      password: '',
      isMessageError: false,
      isMessageInputErrorUsername: false,
      isMessageInputErrorPassword: false,
      isOpenPackage: false,
    }
  },
  created() {
    Account.bzbsAnalyticTracking('login_page', 'login', 'view_login', 'on view')
    this.checkLangStorage().then(() => {
      this.handleLoading(false)
      this.handleShowLanguage()
      this.init()
    })
  },
  methods: {
    handleShowLanguage() {
      this.currentLocale = Locale.getLocaleShort()
    },
    init() {
      this.handleFooter(true)

      try {
        let parse = JSON.parse(localStorage.getItem('bzbsCRMConfig'))
        if (parse) {
          this.isOpenPackage = parse.buzzebeesConfigSelfOnBoard
            ? parse.buzzebeesConfigSelfOnBoard
            : false
        } else {
          this.isOpenPackage = Vue.bzbsConfig.businessRule.open.package
        }
      } catch (e) {
        this.isOpenPackage = Vue.bzbsConfig.businessRule.open.package
      }
      if (
        this.$route.query.merge &&
        this.$route.query.username &&
        this.$route.query.password
      ) {
        this.username = this.$route.query.username
        this.password = this.$route.query.password
        this.submitLogin()
      }
    },
    config() {
      return Vue.bzbsConfig
    },
    submitLogin() {
      this.handleLoading(true)
      this.isMessageError = false
      this.isMessageInputErrorUsername = false
      this.isMessageInputErrorPassword = false
      if (this.checkForm()) {
        Account.bzbsAnalyticTracking(
          'login_page',
          'login',
          'click_login',
          'on click',
        )
        return new Promise(resolve => {
          Account.apiBzbsLogin(this.username, this.password)
            .then(res => {
              Account.apiLoginStampMerchant(this.username, this.password, res)
                .then(() => {
                  if (this.isOpenPackage) {
                    Account.apiAccountInformation().then(res => {
                      Account.apiProfileConfig().then(() => {
                        this.handleLoading(false)
                        if (res.data.RequirePackage) {
                          if (res.data.RequirePackage) {
                            if (res.data.IsExtendExpired) {
                              this.$router.push({
                                name: 'Package',
                                query: { returnPath: 'Dashboard' },
                              })
                            } else {
                              this.$router.push({ name: 'Dashboard' })
                            }
                          } else {
                            this.$router.push({
                              name: 'Package',
                              query: { returnPath: 'Dashboard' },
                            })
                          }
                        } else {
                          this.$router.push({ name: 'Dashboard' })
                        }
                      })
                    })
                  } else {
                    Account.apiProfileConfig().then(() => {
                      this.handleLoading(false)
                      this.$router.push({ name: 'Dashboard' })
                    })
                  }
                })
                .catch(() => {
                  if (this.isOpenPackage) {
                    Account.apiAccountInformation().then(res => {
                      this.handleLoading(false)
                      if (res.data.RequirePackage) {
                        if (res.data.RequirePackage) {
                          if (res.data.IsExtendExpired) {
                            this.$router.push({
                              name: 'Package',
                              query: { returnPath: 'Dashboard' },
                            })
                          } else {
                            this.$router.push({ name: 'Dashboard' })
                          }
                        } else {
                          this.$router.push({
                            name: 'Package',
                            query: { returnPath: 'Dashboard' },
                          })
                        }
                      } else {
                        this.$router.push({ name: 'Dashboard' })
                      }
                    })
                  } else {
                    this.handleLoading(false)
                    this.$router.push({ name: 'Dashboard' })
                  }
                })
              resolve(res.data)
            })
            .catch(error => {
              this.handleLoading(false)
              this.isMessageError = true
              resolve(error)
            })
        })
      } else {
        this.handleLoading(false)
      }
    },
    checkForm() {
      if (!this.username) {
        this.isMessageInputErrorUsername = true
        this.isMessageInputErrorPassword = false
      }
      if (!this.password) {
        this.isMessageInputErrorPassword = true
        this.isMessageInputErrorUsername = false
      }
      if (!this.password && !this.username) {
        this.isMessageError = true
        this.isMessageInputErrorPassword = false
        this.isMessageInputErrorUsername = false
      }
      if (
        !this.isMessageInputErrorUsername &&
        !this.isMessageInputErrorPassword &&
        !this.isMessageError
      )
        return true
    },
    checkPassword(pwString) {
      var strength = 0

      // strength += /[A-Z]+/.test(pwString) ? 1 : 0
      // strength += /[a-z]+/.test(pwString) ? 1 : 0
      // strength += /[0-9]+/.test(pwString) ? 1 : 0
      strength += pwString.length >= 6 ? 1 : 0

      switch (strength) {
        case 1:
          return false
        default:
          return true
      }
    },
    async switchLang(code) {
      Account.bzbsAnalyticTracking(
        'login_page',
        'login',
        'click_change_language',
        'on click',
      )
      this.handleLoading(true)
      this.updateLanguage(code)
      await this.setLang(code)
      this.$router.go()
    },
    updateLanguage(code) {
      var locale
      switch (code) {
        case 'th':
          locale = 1054
          break
        case 'en':
          locale = 1033

          break
        case 'ph':
          locale = 13321

          break
        default:
          locale = 1033
      }
      return new Promise(resolve => {
        BzbsProfile.updateLanguage(locale)
          .then(res => {
            var CRMUserCookie = Cache.get('CRMUser')
            CRMUserCookie.Token = res.data.data
            Cache.set('CRMUser', CRMUserCookie)
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
            resolve(err)
          })
      })
    },
    handleLanguage(e) {
      this.handleLoading(true)
      Vue.$cookies.set('BzbsLocale', e, null, null, null, true, 'Strict')
      window.location.reload()
    },
    handleForgotPassword() {
      Account.bzbsAnalyticTracking(
        'login_page',
        'login',
        'click_forgot_password',
        'on click',
      )
      this.$router.push('/Account/ForgotPassword')
    },
    handleRegister() {
      Account.bzbsAnalyticTracking(
        'login_page',
        'login',
        'click_create_account',
        'on click',
      )
      this.$router.push('/Account/Registervue')
    },
  },
}
</script>

<style lang="scss">
@import '@/style/main.scss';

.logo-image {
  width: fit-content;
  height: fit-content;
}

.box-login {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 12px;
  background-color: $color-white;
  padding: 30px;
  transform: translate(-50%, -50%);
  box-shadow: 0 16px 48px 0 $color-black-2b;
  width: 445px;
  .title-login {
    font-size: 48px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: $black;
  }

  .ant-input {
    height: 40px;
    font-size: 15px;
  }

  .anticon svg {
    color: $color-black-32;
  }

  .btn_login {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 16px 0;
    padding: 10px;
    background-color: $primary;
    border: initial;
    font-size: 16px;
    font-weight: bold;
    color: $color-white;
  }
  .text-userpw {
    line-height: 24px;
    font-size: 14px;
    font-weight: bold;
    color: $color-grey-42;
  }
  .forgotpw {
    font-size: 14px;
    font-weight: 500;
    color: $color-blue-f3;
    text-decoration: underline;
    cursor: pointer;
  }
  .language {
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.25px;

    .ant-select {
      min-width: 91px;
    }
  }
  .lbl-error {
    font-size: 14px;
    line-height: 1.43;
    color: $color-red-52;
    padding-left: 5px;
  }
  .iconlang {
    width: 15px;
    height: 15px;

    border-radius: 4px;
  }
  .ant-alert-with-description.ant-alert-no-icon {
    padding: 10px;
  }
  .btn-register-login {
    color: $color-blue-f3;
    font-size: 14px;
    font-weight: 500;
  }
  .login-didnot {
    font-size: 14px;
    color: $color-grey-42;
  }
}
@media only screen and (max-device-width: 600px) and (orientation: portrait) {
  .box-login {
    width: 80%;
  }
}

@media only screen and (max-device-width: 600px) and (orientation: landscape) {
  .box-login {
    width: 80%;
  }
}
</style>
